<template>
  <el-container class="login">
    <div class="loginVue w-100" style="padding-top: 70px">
      <div class="logo text-center">
        <img src="@/assets/login/logo.webp" class="LOGO" />
      </div>
      <div class="loginBtn flex align-center flex-center cursor" :class="{ notallow: isConnect, op: isConnect }"
        @click="connectWallet()">
        <img src="@/assets/login/metaMask.png" />
        <img class="mx-5" src="@/assets/login/tp.png" />
        <img src="@/assets/login/okex.svg" />
        <span class="bold">{{ $t("login.metamask")}}<template v-if="isConnect">...</template></span>
      </div>
      <div class="flex align-center flex-center cursor" style="margin-top:15px;opacity:0.8;" @click="emaliBox = true">
        <img src="@/assets/login/email.png" style="width:30px;height:auto;" />
        <span class="bold"> {{ $t("login.email") }}</span>
      </div>
      <!-- <div class="card_box bold">
        <p>{{ $t("login.tiptitle") }}<br />
          {{ $t("login.tip1") }}<br />
          {{ $t("login.tip2") }}<br />
          {{ $t("login.tip3") }}<br />
          {{ $t("login.tip4") }} <br />
          {{ $t("login.tip5") }}<br />
          {{ $t("login.tip6") }}<br>
          <a :href="$t('login.guideUrl')" style="color:#14E0B6;" target="_blank">{{ $t("login.guideText") }}</a>
        </p>
      </div> -->

      <!-- 邮箱登录弹框 -->
      <el-dialog :visible.sync="emaliBox" :show-close="false" :center="true">
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" placeholder="email" v-model="loginForm.email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" placeholder="password" v-model="loginForm.login_password" type="password"
              @keyup.enter.native="emailLoginFun()"></el-input>
          </el-form-item>
          <div class="flex-between align-center" style="marginbottom: 10px">
            <el-checkbox v-model="checked" @change="change">{{
              $t("login.remember")
            }}</el-checkbox>
            <span class="color cursor" @click="emaliBox = false;forgetPwdBox = true;">{{ $t("login.forget") }}</span>
          </div>
          <el-form-item>
            <el-button style="width: 100%" type="primary" :loading="isLoading" @click="emailLoginFun()">
              {{ $t("messageBox.submit") }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 忘记密码弹框 -->
      <el-dialog :visible.sync="forgetPwdBox" :show-close="true" :center="true">
        <div class="text-center">
          <img src="../assets/login/forget.png" style="width: 120px; height: 120px" />
        </div>
        <h2 class="text-center white">{{ $t("login.forget") }}</h2>
        <p>
          {{ $t("login.content") }}
        </p>
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" placeholder="email" v-model="forgetForm.email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" :loading="isLoading" @click="inpEmail()">
              {{ $t("login.to") }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 发送验证码弹框 -->
      <el-dialog :visible.sync="codeBox" :show-close="true" :center="true">
        <div class="text-center">
          <img src="../assets/login/sendCode.png" style="width: 120px; height: 120px" />
        </div>
        <h2 class="text-center white">{{ $t("forget.title") }}</h2>
        <p v-html="$t('forget.content', { email: forgetForm.email })">
          <!-- {{$t('forget.title')}} <span> {{ forgetForm.email }}.</span> Follow
          the instruction in the email to reset your password -->
        </p>
        <el-form>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="close()">{{
              $t("messageBox.close")
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 设置密码弹框 -->
      <el-dialog :visible.sync="setPwdBox" :show-close="true" :center="true" :title="$t('pass.title')">
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.newPass')" v-model="setForm.pwd" type="password">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.rePass')" v-model="setForm.rePwd" type="password">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.code')" v-model="setForm.code">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="setPwdBox = false;doneBox = true;">
              {{ $t("pass.confirmpas") }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 完成弹框 -->
      <el-dialog :visible.sync="doneBox" :show-close="true" :center="true">
        <div class="text-center">
          <img src="../assets/login/done.png" style="width: 120px; height: 120px" />
        </div>
        <h2 class="text-center white">{{ $t("pass.tip2") }}</h2>
        <p>{{ $t("pass.tip3") }}</p>
        <el-form>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="doneBox = false">
              {{ $t("messageBox.close") }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <bot />
  </el-container>
</template>>

<script>
import test from "../common/test";
import ethUtil from "ethereumjs-util";
import { mapMutations } from "vuex";
import Web3 from 'web3';
export default {
  inject: ["reload"],
  data() {
    return {
      emaliBox: false,
      forgetPwdBox: false,
      codeBox: false,
      setPwdBox: false,
      doneBox: false,
      isLoading: false,
      isConnect: false,
      checked: "",
      loginForm: {
        email: "",
        login_password: "",
      },
      forgetForm: { email: "" },
      setForm: {
        code: "",
        pwd: "",
        rePwd: "",
        email: "",
      },
    };
  },
  created() {
    // 记住账号密码功能
    if (this.$storage.has("accountRemember")) {
      this.loginForm = this.$storage.get("accountRemember");
      this.checked = true;
    } else {
      this.checked = false;
      this.loginForm = {
        email: "",
        login_password: "",
      };
    }
  },
  mounted() { },

  methods: {
    ...mapMutations(["Set_isLogin", "Set_infos"]),
    change(val) {
      this.checked = val;
    },
    async login() {
      this.isConnect = true;
      // not supported by mobile metamask yet
      // ethereum.request({
      //   method: "wallet_requestPermissions",
      //   params: [{ eth_accounts: {} }],
      // })
      //   .then(async (permissions) => {
      //     const accountsPermission = permissions.find(
      //       (permission) => permission.parentCapability === "eth_accounts"
      //     );
      //     if (accountsPermission) {
            const accounts = await ethereum.request({
              method: "eth_requestAccounts",
            });
            console.log('accounts', accounts)
            var data = { address: accounts[0] };
            this.$axios.post("/api/auth/msg", data).then((res) => {
              // let msg = ethUtil.bufferToHex(res.data.msg);
              let msg = res.data.msg;
              let from = accounts[0];
              let params = [msg, from];
              let method = "personal_sign";
              const provider = new Web3(ethereum);
              provider.currentProvider.sendAsync({ method, params, from }, (error, result) => {
                if (error) {
                  this.$message(error.message);
                  this.isConnect = false;
                  return;
                }
                if (result.error) {
                  this.$message(result.error);
                  this.isConnect = false;
                  return;
                }
                let url = window.location.href
                if (url.indexOf('invite=') != -1) {
                  //邀请码
                  data.code = url.substring(url.indexOf('invite=') + 7, url.indexOf('invite=') + 13)
                } else {
                  data.code = ''
                }
                // if (this.$route.query.invite) data.code = this.$route.query.invite; 
                data.signature = result.result;
                console.log('签名参数', data);
                this.$axios.post("/api/auth/access", data).then((res) => {
                  // console.log(res.data.auth);
                  this.$storage.set("token", res.data.auth);
                  this.$store.dispatch("Get_websiteList");
                  this.Set_isLogin(true);
                  this.isConnect = false;
                  this.$router.replace("/account");
                  setTimeout(() => {
                    this.reload();
                  }, 200);
                });
              }
              );
            });
        //   }
        // })
        // .catch((err) => {
        //   if (err.code == 4001) {
        //     this.$message("用户拒绝请求");
        //   } else { this.$message(err.message) }
        //   this.isConnect = false;
        // });
    },
    // 钱包登录
    async connectWallet() {
      let networkVersion;
      const { ethereum } = await window;
      if (!(ethereum && ethereum.isMetaMask))
        return this.$message("請先安裝 MetaMask");
      this.isConnect = true;
      // let networkVersion = await ethereum.request({ method: 'net_version' });
      networkVersion = await ethereum.request({ method: 'eth_chainId' });
      networkVersion = web3.utils.hexToNumber(networkVersion).toString();
      console.log(networkVersion, process.env.VUE_APP_CHAIN);
      if (networkVersion != process.env.VUE_APP_CHAIN) {
        this.isConnect = false;
        try {
          console.log('wallet_switchEthereumChain')
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.numberToHex(Number(process.env.VUE_APP_CHAIN)) }],
          });
        } catch (switchError) {
          if (switchError.code == -32603 || switchError.code == 4902) {
            console.log('wallet_addEthereumChain')
            try {
              console.log('chainId')
              console.log(web3.utils.numberToHex(Number(process.env.VUE_APP_CHAIN)))
              const req = {
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: web3.utils.numberToHex(Number(process.env.VUE_APP_CHAIN)),
                    chainName: process.env.VUE_APP_CHAIN == 97 ? "BSC测试网" : "BSC主网",
                    nativeCurrency: {
                      name: "tbnb",
                      symbol: "BNB",
                      decimals: 18,
                    },
                    rpcUrls:
                      process.env.VUE_APP_CHAIN == 97
                        ? ["https://rpc.ankr.com/bsc_testnet_chapel"]
                        : ["https://bsc-dataseed1.ninicoin.io"],
                    blockExplorerUrls:
                      process.env.VUE_APP_CHAIN == 97
                        ? ["https://testnet.bscscan.com"]
                        : ["https://bscscan.com"],
                  }],
              }
              ethereum.request(req).then((res, err) => {
                console.log('wallet_addEthereumChain success')
                setTimeout(() => {
                  this.connectWallet()
                }, 1000)
              }).catch((error) => {
                console.error('wallet_addEthereumChain error', error)
                this.$message.error(error.message)
              })
            } catch (addError) {
              console.error(addError)
            }
          } else {
            console.error(switchError)
          }
        }
      } else {
        this.login();
      }
    },

    // 邮箱登录
    emailLoginFun() {
      if (!test.email(this.loginForm.email))
        return this.$message("请输入正确的邮箱");
      if (!this.loginForm.login_password) return this.$message("请输入密码");
      this.isLoading = true;
      console.log(this.checked);
      this.$axios
        .post("/api/auth/login/email", this.loginForm)
        .then((res) => {
          if (this.checked)
            this.$storage.set("accountRemember", this.loginForm);
          else this.$storage.remove("accountRemember");
          this.$storage.set("token", res.data.auth);
          this.$store.dispatch("Get_websiteList");
          this.Set_isLogin(true);
          this.$router.replace("/account");
          this.emaliBox = false;
          this.loginForm = {
            email: "",
            login_password: "",
          };
          this.isLoading = false;
          setTimeout(() => {
            this.reload();
          }, 200);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    inpEmail() {
      if (!test.email(this.forgetForm.email))
        return this.$message("请输入正确的邮箱");
      this.isLoading = true;
      this.$axios
        .post("/api/user/forget", { email: this.forgetForm.email })
        .then((res) => {
          this.isLoading = false;
          this.forgetPwdBox = false;
          this.codeBox = true;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    close() {
      this.codeBox = false;
    },
  },
};
</script>>

<style lang="scss" scoped>
.LOGO {
  width: 218px;
  height: 218px;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .LOGO {
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
}
/deep/ .el-dialog {
  background: rgba(41, 43, 57, 0.9) !important;
  border: 1px solid #fffce5 !important;
  width: 380px;
}
.login {
  min-height: 100vh;
  background-image: url("~@/assets/public/bg.jpg");
  background-size: 100% 100%;
}
.op {
  opacity: 0.5;
}
img {
  // width: 400px;
  // height: 190px;
}
.loginBtn {
  width: 310px;
  height: 84px;
  background: url("../assets/login/loginBtn.png") no-repeat;
  background-size: 100% 100%;
  margin: 16px auto 0;
  img {
    width: 30px;
    height: 30px;
    transform: translateY(2px);
  }
  span {
    color: #fff;
    padding-left: 4px;
    text-shadow: 1px 4px 3px #025167;
    font-size: 16px;
  }
}
h2 {
  margin-bottom: 6px;
}
p {
  color: #999;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
  span {
    color: #fff;
  }
}
.card_box {
  width: 450px;
  height: 280px;
  background-image: url("~@/assets/login/card.png");
  background-size: 100% 100%;
  margin: 20px auto;
  padding: 60px 0 0 15px;
  p {
    font-size: 14px;
    text-align: left;
    line-height: 27px;
    color: #ffffff;
    line-height: 1.8;
  }
}
@media screen and (max-width: 678px) {
  .login {
    min-height: 100vh;
    background: url("~@/assets/public/bg.jpg") no-repeat;
    background-size: 230% 100%;
    background-position: -230px 0px;
  }
  img {
    width: 220px;
    height: 120px;
  }
  .loginBtn {
    width: 250px;
    height: 50px;
    background: url("../assets/login/loginBtn.png") no-repeat;
    background-size: 100% 100%;
    margin: 15px auto 0;
    img {
      width: 24px;
      height: 24px;
      transform: translateY(2px);
    }
    span {
      color: #fff;
      padding-left: 4px;
      text-shadow: 1px 4px 3px #025167;
      font-size: 12px;
    }
  }
  .card_box {
    width: 100%;
    p {
      font-size: 14px;
    }
  }
}
</style>